// REPEATED WITH EVERY COFFERS PRODUCT

export const shippingCost = `
  **<span class="orange">Koszt wysyłki</span>**
  \n * Kurier standard (przy kasetonach gdzie suma szerokości i wysokości nie przekracza 200cm) - 50 zł
  \n * Kurier gabaryt (przy kasetonach gdzie suma szerokości i wysokości przekracza 200cm) - 100 zł
  \n * Dowóz osobisty na terenie Warszawy i okolic - 100 zł
  \n * Dowóz osobisty w całej Polsce - wycena indywidualna
  \n Podane ceny są cenami netto.
`;

export const commonDescription = `
  **Reklama świetlna <span class="orange">projekt graficzny</span>**
  \n Skorzystaj z naszego doświadczenia i stwórz wspólnie z nami okazałą zewnętrzną wizytówkę swojej firmy.
  \n Nie ma ograniczeń!
  \n Chcesz się wyróżnić? Stworzyć coś oryginalnego? Kreatywność pozwoli nam przygotować efektowną 
  reklamę niespotykaną nigdzie wcześniej.
  \n Oferujemy profesjonalną pomoc w przygotowaniu pomysłu oraz projektu graficznego.
  \n **<span class="orange">Montaż</span> kasetonów świetlnych**
  \n Oferujemy montaż na terenie Warszawy i okolic, a także w całej Polsce. Każde zlecenie jest wyceniane 
  indywidualnie na podstawie zdjęć miejsca montażu oraz lokalizacji. W razie pytań skontaktuj się z nami!
`;

export const priceListHint = 'Cena dotyczy kasetonu o podanym wymiarze z ramą lakierowaną proszkowo lub oklejoną w dowolnie wybranym kolorze folii oraz z nadrukiem bezpośrednim UV na froncie z plexi. Podane ceny są cenami netto. Cena nie zawiera wybranych opcji dodatkowych, kosztów wysyłki i ewentualnego kosztu montażu.';
export const slogan = '**<span class="center"><span class="orange">Tanie kasetony</span> - Twoja reklama święcąca nad sklepem</span>**';
