export const mainHeader = 'Kaseton reklamowy LED z plexi';
export const pageDescription = 'Kaseton wykonany z plexi to świetna propozycja dla lokalu, który potrzebuje zewnętrznej lub wewnętrznej reklamy świetlnej. Dzięki nam zadbasz o widoczność swojej firmy.';

export const cofferInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**<span class="orange">Czas realizacji</span>**
      \n 5 - 10 dni roboczych. Do czasu realizacji nie jest wliczany czas ewentualnego umówienia terminu montażu.`,
    },
  ],
  rightColumn: [
    {
      article: `## ${mainHeader}
      \n Wybór na ten rodzaj kasetonu powinien paść, gdy chcemy aby cały front był podświetlony. Lico plafonu wykonane jest z bezpośrednio zadrukowanej plexy opal, która rozprasza 
      światło po całości grafiki. Rama wykonana jest z aluminium, które może zostać w swoim standardowym kolorze, możliwe jest również lakierowanie proszkowe w dowolnym 
      kolorze z palety RAL lub oklejenie barwioną folią ORACAL.
      `,
    },
    {
      article: `**Specyfikacja**
      \n * Lico: Plexi opal
      \n * Boki: Rama aluminiowa lakierowana proszkowo 
      \n * lub oklejona w dowolnie wybranym kolorze folii
      \n * Grubość kasetonu: 10cm
      \n * Plecy: Płyta PCV 5mm
      \n * Zasilanie i LED: Mean Well / Refond
      `,
    },
    {
      article: `**Opcje dodatkowe**
      \n * Czujnik zmierzchu:  \n Automatycznie włącza i wyłącza podświetlenie w zależności od pory dnia; 
      możliwość regulacji czułości czujnika - **<span class="orange">100 zł</span>**
      `,
    },
    {
      article: `**Inny wymiar?**
      \n Wycena indywidualna. Kasetony powyżej wymiaru 400x200 cm łączone są z dwóch lub więcej płyt.
      `,
    },
    {
      article: `**Informacje potrzebne przy zamówieniu**
      \n * wybrany rozmiar
      \n * kolor obudowy <a href="https://banerbunny.pl/szablony/kasetony/kolory-obudowy-wzornik.pdf" target="_blank" download>pobierz plik</a>
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">wymiary</span>**
      \n Najczęściej zamawiane wymiary kasetony to:
      \n * kaseton 200 x 60 cm
      \n * kaseton 300 x 60 cm
      \n * kaseton 600 x 60 cm
      \n * kaseton 200 x 80 cm
      \n * kaseton 300 x 80 cm
      \n * kaseton 600 x 80 cm
      `,
    },
  ],
};
